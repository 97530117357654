import React from "react";
import { Wrapper } from "../components/globalElements";
import Layout from "../components/Layout/Layout";
import { TextSplit } from "components";
import { LoginStyled } from "../styles/login.styles";
import ResetPassForm from "../components/Onboard/ResetPassForm";
import OnboardFooter from "../components/Onboard/OnboardFooter";

const ResetPassword = () => {
  return (
    <Layout title="Reset Password" fullScreen={true} footer={false}>
      <Wrapper style={{ marginBottom: "5rem" }}>
        <TextSplit>
          <LoginStyled>
            <h1 className="heading">Reset Password</h1>
            <p className="paragraph"></p>

            <ResetPassForm />
            <div className="middle-line"></div>

            <OnboardFooter />
          </LoginStyled>
        </TextSplit>
      </Wrapper>
    </Layout>
  );
};

export default ResetPassword;
