import React, { useEffect, useRef } from "react";
import { Gap } from "../../styles/login.styles";
import { useFormik } from "formik";
import { navigate } from "gatsby";
import { auth } from "../../utils/firebase";
import { useLocation } from "@reach/router";
import { toast } from "react-toastify";

const ResetPassForm = () => {
  const oobCode = useRef(null);
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    oobCode.current = queryParams.get("oobCode");
    console.log(oobCode.current);
    if (!oobCode.current) {
      navigate("/login");
    }
  }, []);

  const validate = (values) => {
    let errors = {};
    if (!values.password) {
      errors.password = "⋆Required";
    } else if (values.password.length < 8) {
      errors.password = "Password must be 8 characters long";
    }
    if (!values.confirmPassword) {
      errors.confirmPassword = "⋆Required";
    }
    if (values.password && values.confirmPassword) {
      if (values.password !== values.confirmPassword) {
        errors.confirmPassword = "Passwords not matched";
      }
    }
    return errors;
  };

  const initialValues = {
    password: "",
    confirmPassword: "",
  };

  const onSubmit = async (values, onSubmitProps) => {
    console.log("submit form");
    auth
      .confirmPasswordReset(oobCode.current, values.password)
      .then(() => {
        console.log("success");
        toast.success("Password has been reset!");
        onSubmitProps.resetForm();
        navigate("/login");
      })
      .catch((err) => {
        console.log(err);
        toast.error("Some error occured, please try again");
      });
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validate,
  });

  return (
    <>
      <div>
        <label htmlFor="password">New Password</label>
        <input
          type="password"
          className={"input-success"}
          name="password"
          value={formik.values.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        {formik.touched.password && formik.errors.password ? (
          <div className="error-msg">{formik.errors.password}</div>
        ) : null}
      </div>

      <div>
        <label htmlFor="confirmPassword">Confirm Password</label>
        <input
          type="password"
          className={"input-success"}
          name="confirmPassword"
          value={formik.values.confirmPassword}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
          <div className="error-msg">{formik.errors.confirmPassword}</div>
        ) : null}
      </div>

      <Gap mt={"14px"}></Gap>

      <button
        className="button-filled"
        disabled={!(formik.isValid && formik.dirty)}
        type="submit"
        onClick={formik.submitForm}
      >
        Reset Password
      </button>
    </>
  );
};

export default ResetPassForm;
